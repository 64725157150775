import { default as _91_46_46_46token_93pQtcb4WjnfMeta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/authorization-social/[...token].vue?macro=true";
import { default as indexFihRbQCWTSMeta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/authorization/index.vue?macro=true";
import { default as indexTZdvFBQFBoMeta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/api/index.vue?macro=true";
import { default as indexvaWf8MGXuEMeta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/index.vue?macro=true";
import { default as indexE5DoG7ZHH3Meta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/numbers-history/index.vue?macro=true";
import { default as index9LLIzyrMYoMeta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/payments/index.vue?macro=true";
import { default as indexsNw7W8JacSMeta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/profile/index.vue?macro=true";
import { default as indexhHoQsvhow4Meta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/forgot-password/index.vue?macro=true";
import { default as indexYwbx9x1uKbMeta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/index.vue?macro=true";
import { default as indexbrxOucSAEPMeta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/payment/result/index.vue?macro=true";
import { default as indexxMksVFnQFnMeta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/registration/index.vue?macro=true";
import { default as _91_46_46_46token_93Np53fK32AeMeta } from "/var/www/smscode-frontend/smscode-front-20241213100551/pages/reset-password/[...token].vue?macro=true";
export default [
  {
    name: _91_46_46_46token_93pQtcb4WjnfMeta?.name ?? "authorization-social-token___en___default",
    path: _91_46_46_46token_93pQtcb4WjnfMeta?.path ?? "/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93pQtcb4WjnfMeta || {},
    alias: _91_46_46_46token_93pQtcb4WjnfMeta?.alias || [],
    redirect: _91_46_46_46token_93pQtcb4WjnfMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93pQtcb4WjnfMeta?.name ?? "authorization-social-token___en",
    path: _91_46_46_46token_93pQtcb4WjnfMeta?.path ?? "/en/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93pQtcb4WjnfMeta || {},
    alias: _91_46_46_46token_93pQtcb4WjnfMeta?.alias || [],
    redirect: _91_46_46_46token_93pQtcb4WjnfMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93pQtcb4WjnfMeta?.name ?? "authorization-social-token___ru",
    path: _91_46_46_46token_93pQtcb4WjnfMeta?.path ?? "/ru/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93pQtcb4WjnfMeta || {},
    alias: _91_46_46_46token_93pQtcb4WjnfMeta?.alias || [],
    redirect: _91_46_46_46token_93pQtcb4WjnfMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: indexFihRbQCWTSMeta?.name ?? "authorization___en___default",
    path: indexFihRbQCWTSMeta?.path ?? "/authorization",
    meta: indexFihRbQCWTSMeta || {},
    alias: indexFihRbQCWTSMeta?.alias || [],
    redirect: indexFihRbQCWTSMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexFihRbQCWTSMeta?.name ?? "authorization___en",
    path: indexFihRbQCWTSMeta?.path ?? "/en/authorization",
    meta: indexFihRbQCWTSMeta || {},
    alias: indexFihRbQCWTSMeta?.alias || [],
    redirect: indexFihRbQCWTSMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexFihRbQCWTSMeta?.name ?? "authorization___ru",
    path: indexFihRbQCWTSMeta?.path ?? "/ru/authorization",
    meta: indexFihRbQCWTSMeta || {},
    alias: indexFihRbQCWTSMeta?.alias || [],
    redirect: indexFihRbQCWTSMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexTZdvFBQFBoMeta?.name ?? "cabinet-api___en___default",
    path: indexTZdvFBQFBoMeta?.path ?? "/cabinet/api",
    meta: indexTZdvFBQFBoMeta || {},
    alias: indexTZdvFBQFBoMeta?.alias || [],
    redirect: indexTZdvFBQFBoMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexTZdvFBQFBoMeta?.name ?? "cabinet-api___en",
    path: indexTZdvFBQFBoMeta?.path ?? "/en/cabinet/api",
    meta: indexTZdvFBQFBoMeta || {},
    alias: indexTZdvFBQFBoMeta?.alias || [],
    redirect: indexTZdvFBQFBoMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexTZdvFBQFBoMeta?.name ?? "cabinet-api___ru",
    path: indexTZdvFBQFBoMeta?.path ?? "/ru/cabinet/api",
    meta: indexTZdvFBQFBoMeta || {},
    alias: indexTZdvFBQFBoMeta?.alias || [],
    redirect: indexTZdvFBQFBoMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexvaWf8MGXuEMeta?.name ?? "cabinet___en___default",
    path: indexvaWf8MGXuEMeta?.path ?? "/cabinet",
    meta: indexvaWf8MGXuEMeta || {},
    alias: indexvaWf8MGXuEMeta?.alias || [],
    redirect: indexvaWf8MGXuEMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexvaWf8MGXuEMeta?.name ?? "cabinet___en",
    path: indexvaWf8MGXuEMeta?.path ?? "/en/cabinet",
    meta: indexvaWf8MGXuEMeta || {},
    alias: indexvaWf8MGXuEMeta?.alias || [],
    redirect: indexvaWf8MGXuEMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexvaWf8MGXuEMeta?.name ?? "cabinet___ru",
    path: indexvaWf8MGXuEMeta?.path ?? "/ru/cabinet",
    meta: indexvaWf8MGXuEMeta || {},
    alias: indexvaWf8MGXuEMeta?.alias || [],
    redirect: indexvaWf8MGXuEMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexE5DoG7ZHH3Meta?.name ?? "cabinet-numbers-history___en___default",
    path: indexE5DoG7ZHH3Meta?.path ?? "/cabinet/numbers-history",
    meta: indexE5DoG7ZHH3Meta || {},
    alias: indexE5DoG7ZHH3Meta?.alias || [],
    redirect: indexE5DoG7ZHH3Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexE5DoG7ZHH3Meta?.name ?? "cabinet-numbers-history___en",
    path: indexE5DoG7ZHH3Meta?.path ?? "/en/cabinet/numbers-history",
    meta: indexE5DoG7ZHH3Meta || {},
    alias: indexE5DoG7ZHH3Meta?.alias || [],
    redirect: indexE5DoG7ZHH3Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexE5DoG7ZHH3Meta?.name ?? "cabinet-numbers-history___ru",
    path: indexE5DoG7ZHH3Meta?.path ?? "/ru/cabinet/numbers-history",
    meta: indexE5DoG7ZHH3Meta || {},
    alias: indexE5DoG7ZHH3Meta?.alias || [],
    redirect: indexE5DoG7ZHH3Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: index9LLIzyrMYoMeta?.name ?? "cabinet-payments___en___default",
    path: index9LLIzyrMYoMeta?.path ?? "/cabinet/payments",
    meta: index9LLIzyrMYoMeta || {},
    alias: index9LLIzyrMYoMeta?.alias || [],
    redirect: index9LLIzyrMYoMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: index9LLIzyrMYoMeta?.name ?? "cabinet-payments___en",
    path: index9LLIzyrMYoMeta?.path ?? "/en/cabinet/payments",
    meta: index9LLIzyrMYoMeta || {},
    alias: index9LLIzyrMYoMeta?.alias || [],
    redirect: index9LLIzyrMYoMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: index9LLIzyrMYoMeta?.name ?? "cabinet-payments___ru",
    path: index9LLIzyrMYoMeta?.path ?? "/ru/cabinet/payments",
    meta: index9LLIzyrMYoMeta || {},
    alias: index9LLIzyrMYoMeta?.alias || [],
    redirect: index9LLIzyrMYoMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexsNw7W8JacSMeta?.name ?? "cabinet-profile___en___default",
    path: indexsNw7W8JacSMeta?.path ?? "/cabinet/profile",
    meta: indexsNw7W8JacSMeta || {},
    alias: indexsNw7W8JacSMeta?.alias || [],
    redirect: indexsNw7W8JacSMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexsNw7W8JacSMeta?.name ?? "cabinet-profile___en",
    path: indexsNw7W8JacSMeta?.path ?? "/en/cabinet/profile",
    meta: indexsNw7W8JacSMeta || {},
    alias: indexsNw7W8JacSMeta?.alias || [],
    redirect: indexsNw7W8JacSMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexsNw7W8JacSMeta?.name ?? "cabinet-profile___ru",
    path: indexsNw7W8JacSMeta?.path ?? "/ru/cabinet/profile",
    meta: indexsNw7W8JacSMeta || {},
    alias: indexsNw7W8JacSMeta?.alias || [],
    redirect: indexsNw7W8JacSMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexhHoQsvhow4Meta?.name ?? "forgot-password___en___default",
    path: indexhHoQsvhow4Meta?.path ?? "/forgot-password",
    meta: indexhHoQsvhow4Meta || {},
    alias: indexhHoQsvhow4Meta?.alias || [],
    redirect: indexhHoQsvhow4Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexhHoQsvhow4Meta?.name ?? "forgot-password___en",
    path: indexhHoQsvhow4Meta?.path ?? "/en/forgot-password",
    meta: indexhHoQsvhow4Meta || {},
    alias: indexhHoQsvhow4Meta?.alias || [],
    redirect: indexhHoQsvhow4Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexhHoQsvhow4Meta?.name ?? "forgot-password___ru",
    path: indexhHoQsvhow4Meta?.path ?? "/ru/forgot-password",
    meta: indexhHoQsvhow4Meta || {},
    alias: indexhHoQsvhow4Meta?.alias || [],
    redirect: indexhHoQsvhow4Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexYwbx9x1uKbMeta?.name ?? "index___en___default",
    path: indexYwbx9x1uKbMeta?.path ?? "/",
    meta: indexYwbx9x1uKbMeta || {},
    alias: indexYwbx9x1uKbMeta?.alias || [],
    redirect: indexYwbx9x1uKbMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexYwbx9x1uKbMeta?.name ?? "index___en",
    path: indexYwbx9x1uKbMeta?.path ?? "/en",
    meta: indexYwbx9x1uKbMeta || {},
    alias: indexYwbx9x1uKbMeta?.alias || [],
    redirect: indexYwbx9x1uKbMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexYwbx9x1uKbMeta?.name ?? "index___ru",
    path: indexYwbx9x1uKbMeta?.path ?? "/ru",
    meta: indexYwbx9x1uKbMeta || {},
    alias: indexYwbx9x1uKbMeta?.alias || [],
    redirect: indexYwbx9x1uKbMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrxOucSAEPMeta?.name ?? "payment-result___en___default",
    path: indexbrxOucSAEPMeta?.path ?? "/payment/result",
    meta: indexbrxOucSAEPMeta || {},
    alias: indexbrxOucSAEPMeta?.alias || [],
    redirect: indexbrxOucSAEPMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrxOucSAEPMeta?.name ?? "payment-result___en",
    path: indexbrxOucSAEPMeta?.path ?? "/en/payment/result",
    meta: indexbrxOucSAEPMeta || {},
    alias: indexbrxOucSAEPMeta?.alias || [],
    redirect: indexbrxOucSAEPMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrxOucSAEPMeta?.name ?? "payment-result___ru",
    path: indexbrxOucSAEPMeta?.path ?? "/ru/payment/result",
    meta: indexbrxOucSAEPMeta || {},
    alias: indexbrxOucSAEPMeta?.alias || [],
    redirect: indexbrxOucSAEPMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexxMksVFnQFnMeta?.name ?? "registration___en___default",
    path: indexxMksVFnQFnMeta?.path ?? "/registration",
    meta: indexxMksVFnQFnMeta || {},
    alias: indexxMksVFnQFnMeta?.alias || [],
    redirect: indexxMksVFnQFnMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexxMksVFnQFnMeta?.name ?? "registration___en",
    path: indexxMksVFnQFnMeta?.path ?? "/en/registration",
    meta: indexxMksVFnQFnMeta || {},
    alias: indexxMksVFnQFnMeta?.alias || [],
    redirect: indexxMksVFnQFnMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexxMksVFnQFnMeta?.name ?? "registration___ru",
    path: indexxMksVFnQFnMeta?.path ?? "/ru/registration",
    meta: indexxMksVFnQFnMeta || {},
    alias: indexxMksVFnQFnMeta?.alias || [],
    redirect: indexxMksVFnQFnMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93Np53fK32AeMeta?.name ?? "reset-password-token___en___default",
    path: _91_46_46_46token_93Np53fK32AeMeta?.path ?? "/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93Np53fK32AeMeta || {},
    alias: _91_46_46_46token_93Np53fK32AeMeta?.alias || [],
    redirect: _91_46_46_46token_93Np53fK32AeMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93Np53fK32AeMeta?.name ?? "reset-password-token___en",
    path: _91_46_46_46token_93Np53fK32AeMeta?.path ?? "/en/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93Np53fK32AeMeta || {},
    alias: _91_46_46_46token_93Np53fK32AeMeta?.alias || [],
    redirect: _91_46_46_46token_93Np53fK32AeMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93Np53fK32AeMeta?.name ?? "reset-password-token___ru",
    path: _91_46_46_46token_93Np53fK32AeMeta?.path ?? "/ru/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93Np53fK32AeMeta || {},
    alias: _91_46_46_46token_93Np53fK32AeMeta?.alias || [],
    redirect: _91_46_46_46token_93Np53fK32AeMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/smscode-front-20241213100551/pages/reset-password/[...token].vue").then(m => m.default || m)
  }
]